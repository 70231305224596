import { createRouter, createWebHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";
import pb from "@/pocketbase.js";

const beforeEnter = (to, from, next) => {
  const user = pb.authStore.model;
  if (!user) {
    next({
      path: "/signin",
    });
    return;
  }
  if (!user.enabled) {
    next({
      path: "/forbidden",
    });
    return;
  }
  next();
};

const routes = [
  {
    path: "/main",
    name: "main",
    redirect: "/",
    component: () => import("@/views/MainView.vue"),
    children: [
      {
        path: "/",
        name: "home",
        component: HomeView,
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },
      {
        path: "/me",
        name: "me",
        component: () => import("@/views/Me/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Perfil de usuario
      {
        path: "/profile/:user_id",
        name: "profile",
        redirect: `/profile/${
          pb.authStore.model ? pb.authStore.model.id : "unknow"
        }/setting`,
        component: () => import("@/views/User/ProfileView.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
        children: [
          {
            path: "/profile/:user_id/setting",
            name: "profile-setting",
            component: () => import("@/views/User/ProfileSettingView.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
          {
            path: "/profile/:user_id/info",
            name: "profile-info",
            component: () => import("@/views/User/InfoView.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
          {
            path: "/profile/:user_id/family",
            name: "profile-family",
            component: () => import("@/views/User/FamilyView.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
          {
            path: "/profile/:user_id/study",
            name: "profile-study",
            component: () => import("@/views/User/StudyView.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
          {
            path: "/profile/:user_id/account",
            name: "profile-account",
            component: () => import("@/views/User/AccountView.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
        ],
      },
      // #region Personal
      {
        path: "/user",
        name: "user",
        component: () => import("@/views/User/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },
      {
        path: "/user/:user_id/info",
        name: "user-info",
        component: () => import("@/views/User/InfoView.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },
      {
        path: "/user/:user_id/family",
        name: "user-family",
        component: () => import("@/views/User/FamilyView.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },
      {
        path: "/user/:user_id/study",
        name: "user-study",
        component: () => import("@/views/User/StudyView.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },
      {
        path: "/user/:user_id/account",
        name: "user-account",
        component: () => import("@/views/User/AccountView.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Inventario
      {
        path: "/item",
        name: "item",
        redirect: "/item/home",
        component: () => import("@/views/Item/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
        children: [
          {
            path: "/item/home",
            name: "item-home",
            component: () => import("@/views/Item/Home/View.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
          {
            path: "/item/device",
            name: "item-device",
            component: () => import("@/views/Item/Device/View.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
          {
            path: "/item/suply",
            name: "item-suply",
            component: () => import("@/views/Item/Suply/View.vue"),
            beforeEnter: (to, from, next) => {
              beforeEnter(to, from, next);
            },
          },
        ],
      },

      // #region Permisos
      {
        path: "/permission",
        name: "permission",
        component: () => import("@/views/Permission/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Contratos
      {
        path: "/contract",
        name: "contract",
        component: () => import("@/views/Contract/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Documentos
      {
        path: "/documentation",
        name: "documentation",
        component: () => import("@/views/Documentation/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Boletas de pago de los trabajadores
      {
        path: "/user-paystub",
        name: "user-paystub",
        component: () => import("@/views/UserPaystub/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region SGI Documentación
      {
        path: "/sgi-document",
        name: "sgi-document",
        component: () => import("@/views/Sgi/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },
      {
        path: "/sgi-document/:document_id/history",
        name: "sgi-document-history",
        component: () => import("@/views/Sgi/DocumentHistoryView.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Boletas de pago
      {
        path: "/boletas-de-pago",
        name: "boletas-de-pago",
        component: () => import("@/views/Paystub/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Buzón de sugerencias
      {
        path: "/buzon-de-sugerencias",
        name: "buzon-de-sugerencias",
        component: () => import("@/views/SuggestionBox/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },

      // #region Sgi
      {
        path: "/sgi-doc",
        name: "sgi-doc",
        component: () => import("@/views/UserSgi/View.vue"),
        beforeEnter: (to, from, next) => {
          beforeEnter(to, from, next);
        },
      },
    ],
  },
  // #region Inicio de sesión
  {
    path: "/signin",
    name: "signin",
    component: () => import("@/views/Auth/SigninView.vue"),
    beforeEnter: (to, from, next) => {
      const user = pb.authStore.model;
      if (user) {
        next({
          path: "/",
        });
        return;
      }
      next();
    },
  },
  {
    path: "/recover-password",
    name: "recover-password",
    component: () => import("@/views/Auth/RecoverPasswordView.vue"),
    beforeEnter: (to, from, next) => {
      const user = pb.authStore.model;
      if (user) {
        next({
          path: "/",
        });
        return;
      }
      next();
    },
  },
  // #region AUTH/Confirmar cambio de email
  {
    path: "/auth/confirm-email-change/:token",
    name: "confirm-email-change",
    component: () => import("@/views/Auth/ConfirmEmailChangeView.vue"),
    beforeEnter: (to, from, next) => {
      beforeEnter(to, from, next);
    },
  },
  // #region AUTH/Confirmar reseteo de contraseña
  {
    path: "/auth/confirm-password-reset/:token",
    name: "confirm-password-reset",
    component: () => import("@/views/Auth/ConfirmPasswordResetView.vue"),
    beforeEnter: (to, from, next) => {
      const user = pb.authStore.model;
      if (user) {
        next({
          path: "/",
        });
        return;
      }
      next();
    },
  },
  {
    path: "/about",
    name: "about",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: () => import("@/views/ForbiddenView.vue"),
    beforeEnter: (to, from, next) => {
      const user = pb.authStore.model;
      if (!user) {
        next({
          path: "/signin",
        });
        return;
      }
      if (user.enabled) {
        next({
          path: "/",
        });
        return;
      }
      next();
    },
  },
  // #region Reportes
  {
    path: "/user/:user_id/full-info-report",
    name: "user-full-info-report",
    component: () => import("@/views/User/Report/FullInfoReport.vue"),
    beforeEnter: (to, from, next) => {
      beforeEnter(to, from, next);
    },
  },
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("@/views/PageNotFoundView.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
